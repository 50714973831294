import * as React from "react";
import Layout from "../components/Layout";
import { HtmlHead, ResponsiveImage, Link } from "@cdv/jazz-web/components";
import { Typography, Grid, Container, Box } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export const Head = () => (
  <HtmlHead title="O nás | Metodické centrum pro dopravní psychologii">
    <meta name="foo" content="bar" />
  </HtmlHead>
);

export default function Page() {
  const aCss = {
    a: {
      textDecoration: "none",
      color: "#e05206",
      borderBottom: "1px solid transparent",
      transitionDuration: "0.3s",
    },
    "a:hover": {
      borderBottom: "1px solid #e05206",
      transitionDuration: "0.3s",
    },
  };
  return (
    <Layout isIndex={false}>
      <Container sx={aCss}>
        <Box sx={{ marginTop: "120px" }}>
          <Typography variant="h2" sx={{ fontSize: "40px" }}>
            Co je Metodické centrum pro
            <br /> dopravní psychlogii a k čemu slouží?
          </Typography>
          <Typography>
            Jde o odborné pracoviště Centra dopravního výzkumu zaměřené na přípravu a koordinaci
            terapeutických programů pro řidiče.{" "}
          </Typography>
          <Typography variant="h3" sx={{ fontSize: "24px" }}>
            Jeho hlavním úkolem je:{" "}
          </Typography>
          <Box>
            <ul>
              <li>metodické vedení terapeutických programů, </li>
              <li>vstupní a průběžné vzdělávání lektorů terapeutických programů, </li>
              <li>udělování a odnímání akreditací lektorů, </li>
              <li>kontrola kvality poskytovaných programů,</li>
              <li>provoz informačního internetového portálu.</li>
            </ul>
          </Box>
          <Typography>
            Od 1. 7. 2023 je jeho činnost definována na základě novelizace zákona č. 361/2000 Sb.
            <br /> Zaujalo Vás téma celostátního metodického centra a chcete vědět o jeho nabídce{" "}
            <Link to="/o-nas">více?</Link>
          </Typography>
          <Typography variant="h3" sx={{ fontSize: "24px" }}>
            Co jsou terapeutické programy?
          </Typography>
          <Typography sx={{ fontWeight: "700", marginTop: "10px" }}>
            Terapeutický program je definován zákonem č. 361/2000 Sb.{" "}
          </Typography>
          <Typography>
            Jde o terapeuticko-vzdělávací program pro řidiče s problematickým průběhem praxe –
            pachatele přestupků a trestných činů v dopravě. Jeho cílem je prevence rizikového
            chování v dopravě. Zaměřuje se na změnu postojů a životního stylu účastníků a na
            posílení uvědomění následků rizikového chování za volantem.
          </Typography>
          <Typography variant="h3" sx={{ fontSize: "24px" }}>
            Jak terapeutický program probíhá?
          </Typography>
          <Box>
            <ul>
              <li>Program je skupinový, účastní se jej zpravidla 6–12 osob – řidičů, </li>
              <li>
                je veden jedním lektorem se zvláštní kvalifikací, zpravidla se jedná o
                psychologa/psycholožku s terapeutickou praxí,{" "}
              </li>
              <li>probíhá v 6 blocích po 4 hodinách, obvyklý je jeden blok týdně. </li>
            </ul>
          </Box>
          <Typography variant="h3" sx={{ fontSize: "24px" }}>
            V Metodickém centru pracují:
          </Typography>
          <Box sx={{ marginTop: "60px" }}>
            <Grid container spacing={4}>
              <Grid item md={6}>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item md={4}>
                      <StaticImage
                        src="../images/team/kureckova.png"
                        alt="Mgr. Veronika Kurečková"
                      />
                    </Grid>
                    <Grid item md={8}>
                      <Typography sx={{ marginTop: "0px" }}>
                        <strong>Mgr. Veronika Kurečková</strong>
                        <br />
                        odborná garantka Metodického centra
                      </Typography>
                      <Typography sx={{ textAlign: "left", fontSize: "14px", marginTop: "10px" }}>
                        Má na starosti přípravu odborné koncepce terapeutických kurzů, systém
                        metodické podpory lektorů, přípravu metodik a také vzdělávání lektorů.
                      </Typography>
                      <Typography>
                        kontakt:{" "}
                        <Link to="mailto:veronika.kureckova@cdv.cz">veronika.kureckova@cdv.cz</Link>
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item md={4}>
                      <StaticImage
                        src="../images/team/trepacova.png"
                        alt="Mgr. Martina Trepáčová"
                      />
                    </Grid>
                    <Grid item md={8}>
                      <Typography sx={{ marginTop: "0px" }}>
                        <strong>Mgr. Martina Trepáčová</strong>
                        <br />
                        psycholožka, koordinátorka pilotáže
                      </Typography>
                      <Typography sx={{ textAlign: "left", fontSize: "14px", marginTop: "10px" }}>
                        Má na starosti pilotáž programů, přípravu metodických podkladů a koordinaci
                        odborných aktivit.
                      </Typography>
                      <Typography>
                        kontakt:{" "}
                        <Link to="mailto:martina.trepacova@cdv.cz">martina.trepacova@cdv.cz</Link>
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item md={3}></Grid>
              <Grid item md={6}>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item md={4}>
                      <StaticImage src="../images/team/rosenberg.png" alt="Mgr. Tomáš Rosenberg" />
                    </Grid>
                    <Grid item md={8}>
                      <Typography sx={{ marginTop: "0px" }}>
                        <strong>Mgr. Tomáš Rosenberg</strong>
                        <br />
                        koordinátor Metodického centra
                      </Typography>
                      <Typography sx={{ textAlign: "left", fontSize: "14px", marginTop: "10px" }}>
                        Má na starosti koordinaci aktivit Metodického centra, přípravu systému
                        administrativní podpory, komunikaci s Probační a mediační službou a OČTŘ.
                      </Typography>
                      <Typography>
                        kontakt:{" "}
                        <Link to="mailto:tomas.resenberg@cdv.cz">tomas.resenberg@cdv.cz</Link>
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ marginTop: "50px" }}>
            <hr />
          </Box>
          <Box sx={{ marginTop: "50px" }}>
            <Grid container spacing={4}>
              <Grid item md={6}>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item md={4}>
                      <StaticImage
                        src="../images/team/rezac.png"
                        alt="PhDr. Pavel Řezáč, Ph.D., MBA"
                      />
                    </Grid>
                    <Grid item md={8}>
                      <Typography sx={{ marginTop: "0px", textAlign: "left" }}>
                        <strong>PhDr. Pavel Řezáč, Ph.D., MBA</strong>
                        <br />
                        ředitel Metodického centra a ředitel Divize dopravních technologií a
                        lidského faktoru Centra dopravního výzkumu, v. v. i.
                      </Typography>
                      <Typography sx={{ textAlign: "left", fontSize: "14px", marginTop: "10px" }}>
                        Má na starosti supervizi aktivit Metodického centra, komunikace a správu
                        informačního internetového portálu.
                      </Typography>
                      <Typography>
                        kontakt: <Link to="mailto:pavel.rezac@cdv.cz">pavel.rezac@cdv.cz</Link>
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item md={4}>
                      <StaticImage
                        src="../images/team/fric.png"
                        alt="PhDr. Pavel Řezáč, Ph.D., MBA"
                      />
                    </Grid>
                    <Grid item md={8}>
                      <Typography sx={{ marginTop: "0px", textAlign: "left" }}>
                        <strong>Ing. Jindřich Frič, Ph.D.</strong>
                        <br />
                        statutární zástupce Metodického centra a ředitel Centra dopravního výzkumu,
                        v. v. i.
                      </Typography>
                      <Typography
                        sx={{ textAlign: "left", fontSize: "14px", marginTop: "10px" }}
                      ></Typography>
                      <Typography>
                        kontakt: <Link to="mailto:jindrich.fric@cdv.cz">jindrich.fric@cdv.cz</Link>
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Layout>
  );
}
